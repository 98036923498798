import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const options = {
  detection: {
    caches: ["cookie"],
    cookieSameSite: "strict",
    lookupQuerystring: "lng", // default is lng
    lookupCookie: "i18nextLng",
    order: ["querystring", "cookie", "header"], // IMPORTANT
  },
};

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  debug: true,
  fallbackLng: "en",
  detection: options,
});
export default i18n;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.tripenhancer.app/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.tripenhancer.app)

Coded by www.tripenhancer.app

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import landmarks1 from "../../../../assets/images/designblocksimages/landmarks1.jpg";
import landmarks2 from "../../../../assets/images/designblocksimages/landmarks2.jpg";
import landmarks3 from "../../../../assets/images/designblocksimages/landmarks3.jpg";
import history1 from "../../../../assets/images/designblocksimages/history1.jpg";
import history2 from "../../../../assets/images/designblocksimages/history2.jpg";
import history3 from "../../../../assets/images/designblocksimages/history3.jpg";
import towncenter1 from "../../../../assets/images/designblocksimages/towncenter1.jpg";
import towncenter2 from "../../../../assets/images/designblocksimages/towncenter2.jpg";
import towncenter3 from "../../../../assets/images/designblocksimages/towncenter3.jpg";
import events1 from "../../../../assets/images/designblocksimages/events1.jpg";
import events2 from "../../../../assets/images/designblocksimages/events2.jpg";
import events3 from "../../../../assets/images/designblocksimages/events3.jpg";
import etymology1 from "../../../../assets/images/designblocksimages/etymology1.jpg";
import etymology2 from "../../../../assets/images/designblocksimages/etymology2.jpg";
import etymology3 from "../../../../assets/images/designblocksimages/etymology3.jpg";
import mobility1 from "../../../../assets/images/designblocksimages/mobility1.jpg";
import mobility2 from "../../../../assets/images/designblocksimages/mobility2.jpg";
import mobility3 from "../../../../assets/images/designblocksimages/mobility3.jpg";
import economy1 from "../../../../assets/images/designblocksimages/economy1.jpg";
import economy2 from "../../../../assets/images/designblocksimages/economy2.jpg";
import economy3 from "../../../../assets/images/designblocksimages/economy3.jpg";
import geography1 from "../../../../assets/images/designblocksimages/geography1.jpg";
import geography4 from "../../../../assets/images/designblocksimages/geography4.jpg";
import geography3 from "../../../../assets/images/designblocksimages/geography3.jpg";
import gastronomy1 from "../../../../assets/images/designblocksimages/gastronomy1.jpg";
import gastronomy2 from "../../../../assets/images/designblocksimages/gastronomy2.jpg";
import gastronomy3 from "../../../../assets/images/designblocksimages/gastronomy3.jpg";
import culture1 from "../../../../assets/images/designblocksimages/culture1.jpg";
import culture2 from "../../../../assets/images/designblocksimages/culture2.jpg";
import culture3 from "../../../../assets/images/designblocksimages/culture3.jpg";
import religion1 from "../../../../assets/images/designblocksimages/religion1.jpg";
import religion2 from "../../../../assets/images/designblocksimages/religion2.jpg";
import religion3 from "../../../../assets/images/designblocksimages/religion3.jpg";

export default [
  {
    title_nl: "Geschiedenis",
    title: "History",
    description_nl:
      "Duik in het verleden en leer over de historische gebeurtenissen en figuren die je omgeving hebben gevormd.",
    description:
      "Dive into the past and learn about the historical events and figures that shaped your surroundings.",
    items: [
      {
        image: history1,
        name: "",
        count: 0,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: history2,
        name: "",
        count: 0,
        route: "/sections/page-sections/features",
      },
      {
        image: history3,
        name: "",
        count: 0,
        pro: false,
      },
    ],
  },
  {
    title_nl: "Bezienswaardigheden",
    title: "Landmarks",
    description_nl:
      "Ontdek opmerkelijke bezienswaardigheden, verborgen parels en must-visit plekken langs je route.",
    description: "Discover notable landmarks, hidden gems, and must-visit spots along your route.",
    items: [
      {
        image: landmarks1,
        name: "",
        count: 0,
        route: "/sections/navigation/navbars",
      },
      {
        image: landmarks2,
        name: "",
        count: 0,
        route: "/sections/navigation/nav-tabs",
      },
      {
        image: landmarks3,
        name: "",
        count: 0,
        route: "/sections/navigation/pagination",
      },
    ],
  },
  {
    title_nl: "Cultuur",
    title: "Culture",
    description_nl:
      "Ervaar de lokale cultuur, tradities en artistiek erfgoed via boeiende audiobeschrijvingen.",
    description:
      "Experience the local culture, traditions, and artistic heritage through engaging audio descriptions.",
    items: [
      {
        image: culture1,
        name: "",
        count: 0,
        pro: false,
      },
      {
        image: culture2,
        name: "",
        count: 0,
        pro: false,
      },
      {
        image: culture3,
        name: "",
        count: 0,
        route: "/sections/input-areas/forms",
      },
    ],
  },
  {
    title_nl: "Evenementen",
    title: "Events",
    description_nl:
      "Blijf op de hoogte van lokale evenementen en activiteiten, zodat je niets van de actie hoeft te missen.",
    description:
      "Stay updated on local events and happenings, making sure you never miss out on the action.",
    items: [
      {
        image: events1,
        name: "",
        count: 0,
        route: "/sections/attention-catchers/alerts",
      },
      {
        image: events2,
        name: "",
        count: 0,
        pro: false,
      },
      {
        image: events3,
        name: "",
        count: 0,
        route: "/sections/attention-catchers/tooltips-popovers",
      },
    ],
  },
  {
    title_nl: "Etymologie",
    title: "Etymology",
    description_nl:
      "Ontdek de oorsprong en betekenissen achter de namen van plaatsen die je tegenkomt, wat diepte toevoegt aan je reis.",
    description:
      "Uncover the origins and meanings behind the names of places you encounter, adding depth to your journey.",
    items: [
      {
        image: etymology1,
        name: "",
        count: 0,
        route: "/sections/elements/buttons",
      },
      {
        image: etymology2,
        name: "",
        count: 0,
        route: "/sections/elements/avatars",
      },
      {
        image: etymology3,
        name: "",
        count: 0,
        route: "/sections/elements/dropdowns",
      },
    ],
  },
  {
    title_nl: "Mobiliteit",
    title: "Mobility",
    description_nl: "Krijg praktische informatie over vervoersopties en mobiliteit in de omgeving.",
    description: "Get practical information about transportation options and mobility in the area.",
    items: [
      {
        image: mobility1,
        name: "",
        count: 0,
        route: "/sections/elements/buttons",
      },
      {
        image: mobility2,
        name: "",
        count: 0,
        route: "/sections/elements/avatars",
      },
      {
        image: mobility3,
        name: "",
        count: 0,
        route: "/sections/elements/dropdowns",
      },
    ],
  },
  {
    title_nl: "Economie",
    title: "Economy",
    description_nl:
      "Begrijp de economische achtergrond van de regio's die je verkent, van lokale bedrijven tot grote industrieën.",
    description:
      "Understand the economic backdrop of the regions you explore, from local businesses to major industries.",
    items: [
      {
        image: economy1,
        name: "",
        count: 0,
        route: "/sections/elements/buttons",
      },
      {
        image: economy2,
        name: "",
        count: 0,
        route: "/sections/elements/avatars",
      },
      {
        image: economy3,
        name: "",
        count: 0,
        route: "/sections/elements/dropdowns",
      },
    ],
  },
  {
    title_nl: "Geografie",
    title: "Geography",
    description_nl:
      "Leer over de geografische kenmerken en landschappen die de gebieden definiëren die je doorkruist.",
    description:
      "Learn about the geographical features and landscapes that define the areas you traverse.",
    items: [
      {
        image: geography1,
        name: "",
        count: 0,
        route: "/sections/elements/buttons",
      },
      {
        image: geography4,
        name: "",
        count: 0,
        route: "/sections/elements/avatars",
      },
      {
        image: geography3,
        name: "",
        count: 0,
        route: "/sections/elements/dropdowns",
      },
    ],
  },
  {
    title_nl: "Gastronomie",
    title: "Gastronomy",
    description_nl:
      "Geniet van de lokale keuken met inzichten in populaire gerechten, restaurants en voedselcultuur.",
    description:
      "Savor the local cuisine with insights into popular dishes, restaurants, and food culture.",
    items: [
      {
        image: gastronomy1,
        name: "",
        count: 0,
        route: "/sections/elements/buttons",
      },
      {
        image: gastronomy2,
        name: "",
        count: 0,
        route: "/sections/elements/avatars",
      },
      {
        image: gastronomy3,
        name: "",
        count: 0,
        route: "/sections/elements/dropdowns",
      },
    ],
  },
  {
    title_nl: "Stadscentrum",
    title: "Town Centre",
    description_nl:
      "Maak kennis met het hart van de stad en leer over de belangrijkste attracties en centrale plekken.",
    description:
      "Get acquainted with the heart of the town, learning about its main attractions and central spots.",
    items: [
      {
        image: towncenter1,
        name: "",
        count: 0,
        route: "/sections/elements/buttons",
      },
      {
        image: towncenter2,
        name: "",
        count: 0,
        route: "/sections/elements/avatars",
      },
      {
        image: towncenter3,
        name: "",
        count: 0,
        route: "/sections/elements/dropdowns",
      },
    ],
  },
  {
    title_nl: "Religie",
    title: "Religion",
    description_nl:
      "Ontdek de religieuze bezienswaardigheden, praktijken en geschiedenissen die bijdragen aan het spirituele weefsel van het gebied.",
    description:
      "Discover the religious landmarks, practices, and histories that contribute to the area's spiritual fabric.",
    items: [
      {
        image: religion1,
        name: "",
        count: 0,
        route: "/sections/elements/buttons",
      },
      {
        image: religion2,
        name: "",
        count: 0,
        route: "/sections/elements/avatars",
      },
      {
        image: religion3,
        name: "",
        count: 0,
        route: "/sections/elements/dropdowns",
      },
    ],
  },
];

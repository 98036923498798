/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.tripenhancer.app/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.tripenhancer.app)

Coded by www.tripenhancer.app

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Kit 2 React pages
import ContactUs from "pages/LandingPages/ContactUs";

export default function ContactUsPage() {
  return <ContactUs />;
}

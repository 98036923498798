/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.tripenhancer.app/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.tripenhancer.app)

Coded by www.tripenhancer.app

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import androidLogo from "assets/images/logos/logo-android.svg";
import jetpackcomposeLogo from "assets/images/logos/logo-jetpackcompose.svg";
import firebaseLogo from "assets/images/logos/logo-firebase.svg";
import wikipediaLogo from "assets/images/logos/logo-wikipedia.svg";
import tripEnhancerLogo from "assets/images/logos/logo-tripenhancer.svg";

//internationalization
import { useTranslation } from "react-i18next";

function Information() {
  const { t } = useTranslation();

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h2">{t("testimonials.manytypesofpeople")}</MKTypography>
          <MKTypography variant="h2" color="info" textGradient mb={2}>
            {t("testimonials.enjoyonthemove")}
          </MKTypography>
          <MKTypography variant="body1" color="text" mb={2}>
            {t("testimonials.usedby")}
          </MKTypography>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Bart Caris"
              date={t("testimonials.dayago")}
              review={t("testimonials.review1")}
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              color="info"
              name="Jan Bakelants"
              date={t("testimonials.weekago")}
              review={t("testimonials.review2")}
              rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Shiroix1st"
              date={t("testimonials.weeksago")}
              review={t("testimonials.review3")}
              rating={5}
            />
          </Grid>
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={androidLogo} alt="android" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={jetpackcomposeLogo}
              alt="jetpack compose"
              width="50%"
              opacity={0.6}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={firebaseLogo} alt="firebase" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={wikipediaLogo} alt="wikipedia" width="80%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={tripEnhancerLogo}
              alt="TripEnhancer"
              width="50%"
              opacity={0.6}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;

//TODO
/* eslint-disable no-unused-vars */

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/ic-logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "TripEnhancer",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/TripEnhancer/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/TripEnhancer",
    },
    // {
    //   icon: <GitHubIcon />,
    //   link: "https://github.com/creativetimofficial",
    // },

    // { TODO
    //   icon: <YouTubeIcon />,
    //   link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    // },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "/pages/landing-pages/about-us" },
        // { name: "freebies", href: "https://www.tripenhancer.app/templates/free" },
        // { name: "premium tools", href: "https://www.tripenhancer.app/templates/premium" },
        { name: "blog", href: "https://bartcaris.wordpress.com" },
      ],
    },
    {
      name: "resources",
      items: [
        {
          name: "Free Android app",
          href: "https://play.google.com/store/apps/details?id=com.naxx.tripenhancer.free",
        },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "/pages/landing-pages/contact-us" },
        // { name: "knowledge center", href: "https://www.tripenhancer.app/knowledge-center" },
        // { name: "custom development", href: "https://services.creative-tim.com/" },
        // { name: "sponsorships", href: "https://www.tripenhancer.app/sponsorships" },
      ],
    },
    {
      name: "legal",
      items: [
        /*{ name: "terms & conditions", href: "https://www.tripenhancer.app/terms" },*/
        {
          name: "privacy policy",
          href: "https://bartcaris.wordpress.com/tripenhancer-privacy-policy/",
        },
        /*{ name: "licenses (EULA)", href: "https://www.tripenhancer.app/license" },*/
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} TripEnhancer by{" "}
      <MKTypography
        component="a"
        href="https://www.tripenhancer.app"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Naxx
      </MKTypography>
      .
    </MKTypography>
  ),
};

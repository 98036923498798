/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.tripenhancer.app/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.tripenhancer.app)

Coded by www.tripenhancer.app

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable no-unused-vars */

import food1 from "../../../../assets/images/pagesimages/food1.jpg";
import lodging1 from "../../../../assets/images/pagesimages/lodging1.jpg";
import assistance1 from "../../../../assets/images/pagesimages/assistance1.jpg";
import gifts1 from "../../../../assets/images/pagesimages/gifts1.jpg";

//TODO
//internationalization
import { useTranslation } from "react-i18next";
// const { t } = useTranslation();
// let foodanddrinks = t("pages.foodanddrinks");

export default [
  {
    image: food1,
    name: "Food and drinks",
    name_nl: "Eten en drinken",
    route: "/pages/landing-pages/about-us",
  },
  {
    image: lodging1,
    name: "Lodging",
    name_nl: "Logies",
    route: "/pages/landing-pages/contact-us",
  },
  {
    image: assistance1,
    name: "Assistance",
    name_nl: "Assistentie",
    route: "/pages/landing-pages/author",
  },
  {
    image: gifts1,
    name: "Local gifts",
    name_nl: "Lokale geschenken",
    route: "/pages/landing-pages/author",
  },
];

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.tripenhancer.app/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.tripenhancer.app)

Coded by www.tripenhancer.app

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// Material Kit 2 React base styles
import colors from "assets/theme/base/colors";

// Images
import bgFront from "assets/images/Screenshot_articles_framed.png";
import bgBack from "assets/images/Screenshot_foodanddrinks.png";

//internationilization
import { useTranslation } from "react-i18next";

function Information() {
  const { t } = useTranslation();

  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                color={colors.dark}
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    {t("information.title1")}
                    <br />
                    {t("information.title2")}
                  </>
                }
                description={t("information.titledesc")}
              />
              <RotatingCardBack
                image={bgBack}
                title={t("information.discovernearbyplaces")}
                description={t("information.discovernearbyplacesdesc")}
                action={{
                  type: "internal",
                  route: "/sections/page-sections/page-headers",
                  label: "start with the app",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title={t("information.limitednumberofusers")}
                  description={t("information.limitednumberofusersdesc")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title={t("information.installtheapp")}
                  description={t("information.installtheappdesc")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title={t("information.bequick")}
                  description={t("information.bequickdesc")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title={t("information.public")}
                  description={t("information.publicdesc")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
